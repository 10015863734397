import { useState } from "react";

function RegistrationForm() {

    let [message, setMessage] = useState(undefined);
    let [success, setSuccess] = useState(false);

    // Function to get CSRF token
    const getCsrfToken = () => {
        const cookies = document.cookie.split('; ');
        for (let cookie of cookies) {
            const [name, value] = cookie.split('=');
            if (name === 'csrftoken') {
                return value;
            }
        }
        return null;
    };

    const registerUser = async (event) => {
        event.preventDefault();
        const csrfToken = getCsrfToken();
        const formData = new FormData(event.target);
        const formObject = Object.fromEntries(formData.entries());

        try {
            const response = await fetch('/api/register/', {
                method: 'POST', // Ensure this is POST
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,  // Add CSRF token to headers
                },
                body: JSON.stringify(formObject),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Something went wrong');
            }

            const result = await response.json();

            if (result.message)
                setMessage(result.message);
            setSuccess(result.status === 'success')
        } catch (error) {
            setMessage(`Error during registration: ${error.message}`);
            setSuccess(false)
        }
        setTimeout(5000, _ => {
            setMessage(undefined)
        })
    };

    return (
        <div class="sec-right" id="registrationForm">
            <form onSubmit={registerUser}>
                <div class="form-left-address"><h2>Registration</h2></div>
                <div class="form-left">
                    <label for="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Enter your name"
                        required
                    />
                </div>
                <div class="form-right">
                    <label for="mobile">Mobile Number</label>
                    <input
                        type="number"
                        id="mobile"
                        name="mobile"
                        placeholder="Enter your mobile number"
                        required
                    />
                </div>
                <div class="form-left" id="gotra-input-box">
                    <label for="gotra">Gotra</label>
                    <input
                        type="text"
                        id="gotra"
                        name="gotra"
                        placeholder="Enter your Gotra"
                        required
                    />
                </div>
                <div class="form-right">
                    <label for="address">Kulaguru</label>
                    <input
                        type="text"
                        id="kulaguru"
                        name="kulaguru"
                        placeholder="Enter your Kulaguru name"
                        required
                    />
                </div>
                <div class="form-left">
                    <label for="email">Email ID</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter your Email ID"
                        required
                    />
                </div>
                <div class="form-right" id="dob-input-box">
                    <label for="dob">Date of Birth</label>
                    <input type="date" id="dob" name="dob" required />
                </div>
                <div class="form-left" id="gender-input-box">
                    <label for="gender">Gender</label>
                    <select id="gender" name="gender">
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                </div>
                <div class="form-right" id="activities-input-box">
                    <label for="activities">Activities I'm Interested In</label>
                    <select id="activities" name="activities">
                        <option value="">Select Activities</option>
                        <option value="sports">Sports</option>
                        <option value="arts">Arts & Crafts</option>
                        <option value="performing-music">Music</option>
                        <option value="performing-theater">Theater</option>
                        <option value="performing-fitness">Fitness</option>
                        <option value="performing-photography">Photography</option>
                        <option value="performing-technology">Technology</option>
                        <option value="performing-cooking">Cooking</option>
                    </select>
                </div>
                <div class="form-left">
                    <label for="city">City</label>
                    <input
                        id="city"
                        name="city"
                        type="text"
                        placeholder="Enter your location"
                        required
                    />
                </div>
                <div class="form-right" id="team-input-box">
                    <label for="team">Yuvatma Near Me</label>
                    <select id="team" name="team">
                        <option value="">Select City</option>
                        <option value="Bengaluru">Bengaluru</option>
                        <option value="Hubli">Hubli</option>
                        <option value="Bagalkot">Bagalkot</option>
                        <option value="Pune">Pune</option>
                        <option value="Mumbai">Mumbai</option>
                        <option value="Bhagyanagara">Bhagyanagara</option>
                        <option value="Mysuru">Mysuru</option>
                        <option value="Mudhol">Mudhol</option>
                    </select>
                </div>
                <div class="form-left">
                    <label for="pincode">Pincode</label>
                    <input
                        type="number"
                        id="pincode"
                        name="pincode"
                        placeholder="Enter Pincode"
                        required
                    />
                </div>
                
                <div class="form-right">
                    <label for="address">Residential Address</label>
                    <input
                        type="text"
                        id="address"
                        name="address"
                        placeholder="Enter your residential address"
                    />
                </div>
                <button style={{ marginTop: '20px' }} type="submit" >Register</button>
            </form>
                {message && (
                <span style={{ color: success ? 'green' : 'red', display: 'block', marginTop: '10px' }}>
                    {message}
                </span>
            )}
        </div>
    )
}

export default RegistrationForm;
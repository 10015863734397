import './Events.css';
function Events() {

    let events = [
        {
            img: './assets/image39-1.png',
            title: 'Theatre team',
            content: 'Team has performed various dramas'
        },
        {
            img: './assets/image47.png',
            title: 'Yuvagoshti',
            content: 'Empowering youth with Param Poojya Sri Satyatma Teertha Shreepandangalavaru'
        },
        {
            img: './assets/Speech.jpeg',
            title: 'Inspiring minds',
            content: 'Motivational address by Sri Chakravarthy Sulibele'
        },
        {
            img: './assets/recognition.jpeg',
            title: 'Recognition Program',
            content: 'Honoring our 10th and 12th standard achievers'
        },
        {
            img: './assets/image39-1.png',
            title: 'Cultural Walkathons',
            content: 'Celebrating heritage in Bengaluru and Hubli'
        },
        {
            img: './assets/Fan-donation.jpeg',
            title: 'Spreading Joy',
            content: 'Fan donation drive at Anatha Shishu Nilaya'
        }
    ];

    function trim_long_text(x) {
        return (x.length > 200) ? x.substr(0, 200) + '...' : x;
    }

    return (
        <section class="Events inter-paragraph" id="events">
            <div class="Eventpage">
                <div class="EventsContent fc fs">
                    <h2>What We Do</h2>
                    <p style={{ textAlign: "center" }}>
                        At Yuvatma, we host a variety of
                        <b>social, cultural, spiritual and recreational events,</b> all
                        designed to support and nurture the youth in achieving these
                        objectives.
                    </p>
                    <div style={{ marginTop: "30px" }} class="what_we_do">
                        {
                            events.map(item => (
                                <div class='wwd-item fr'>
                                    <img style={{ width: '40%', background: 'rgba(0,0,0,.7)' }} src={item.img} />
                                    <div class='p2 fr' style={{ width: '60%', paddingTop: '10px' }}>
                                        <div class='fc' style={{ height: 'calc(100% - 20px)' }}>
                                            <strong>{item.title}</strong>
                                            <p style={{ overflow: 'hidden', textOverflow: 'ellipsis', margin: '0px' }}>
                                                {trim_long_text(item.content || '')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Events;
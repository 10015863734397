import logo from './logo.svg';
import './App.css';
import RegistrationForm from './components/RegistrationForm';
import Announcements from './components/Announcements';
import Events from './components/Events';

function App() {
  let branches = [
    { city: 'Hubli', instaLink: "https://www.instagram.com/yuvatma_hubli/" },
    { city: 'Bengaluru', instaLink: "https://www.instagram.com/yuvatma/" },
    { city: 'Bagalkot', instaLink: "https://www.instagram.com/yuvatma_bagalkote/" },
    { city: 'Mumbai', instaLink: "https://www.instagram.com/yuvatma.mumbai/" },
    { city: 'Pune', instaLink: "https://www.instagram.com/yuvatma_pune/" },
    { city: 'Hyderabad', instaLink: "https://www.instagram.com/yuvatma_bhagyanagar/" },
    { city: 'Mysore', instaLink: "https://www.instagram.com/yuvatma_mysuru/" }
  ]
  return (
    <div class="noto-sans-display-viewfont">
      <header class="header" id="header">
        <nav class="nav container expletus-sans-heading">
          <a href="/index.html" class="nav_logo">Y</a>
          <div class="nav_menu" id="nav-menu">
            <ul class="nav_list">
              <li class="nav_items"><a href="/#home" class="nav_link active_link"><span
                class="material-symbols-outlined nav_icon">home</span>
                <snap class="nav_name">Home</snap>
              </a></li>
              <li class="nav_items"><a href="/#events" class="nav_link active_link"><span
                class="material-symbols-outlined nav_icon">festival</span>
                <snap class="nav_name">Events</snap>
              </a></li>
              <li class="nav_items"><a href="/jobportal/" class="nav_link active_link"><span
                class="material-symbols-outlined nav_icon">person_add</span>
                <snap class="nav_name">Job Portal</snap>
              </a></li>
              <li class="nav_items"><a href="/#contact" class="nav_link active_link"><span
                class="material-symbols-outlined nav_icon">contact_phone</span>
                <snap class="nav_name">Contact</snap>
              </a></li>
            </ul>
          </div>
        </nav>
      </header>
      <main class="josefin-sans-content">
        <section class="landing inter-paragraph" id="home">
          <div class="sec-left">
            <div class="brand-heading expletus-sans-heading">
              <h2>Welcome to Yuvatma</h2>
            </div>
            <p>
              We are excited to have you join Yuvatma, a dynamic youth wing of Sri
              Uttaradi Matha, founded by Sri Sri 1008 Satyatma Tirtha
              Sripadangalavaru on April 30, 2023. <br />
              Our mission is to empower young individuals to realize their full
              potential and contribute positively to the society.
            </p>
            <div class="" style={{
              justifyContent: 'center',
              textAlign: 'justify',
              display: 'grid',
              width: '-webkit-fill-available'
            }}>
              <h2 style={{ marginTop: '0px', marginBottom: '0px' }}>Our Branches</h2>
              <div class="branch-images">
                {
                  branches.map(b => (
                    <div>
                      <h5>{b.city}</h5>
                      <a href={b.instaLink}>
                        <img src={`/assets/yuvatma-${b.city}.jpeg`} alt={b.city} /></a>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
          <RegistrationForm></RegistrationForm>
        </section>
        <section class="Objectives inter-paragraph" style={{ color: "#6200ff" }}>
          <h2>Our Objectives</h2>
          <div class="objectives-cards">
            <div class="card">
              <img src="/assets/13.png" alt="Encourage Growth" />
              <div class="cardContent">
                <h3>Encourage Growth:</h3>
                <p>
                  Inspire youth to harness their abilities and make a meaningful
                  impact.
                </p>
              </div>
            </div>
            <div class="card">
              <img src="/assets/14.png" alt="Encourage Growth" />
              <div class="cardContent">
                <h3>Skill Development:</h3>
                <p>
                  Provide essential skills for both personal and professional
                  advancement.
                </p>
              </div>
            </div>
            <div class="card">
              <img src="/assets/9.png" alt="Encourage Growth" />
              <div class="cardContent">
                <h3>Community Connection:</h3>
                <p>
                  Emphasize the importance of community and staying connected to
                  one's heritage.
                </p>
              </div>
            </div>
          </div>
        </section>
        <Announcements></Announcements>
        <Events></Events>
        <section class="Contact inter-paragraph" id="contact" style={{ color: "#000eb4" }}>
          <div class="contactDetails">
            <div class="contactConent">
              <h3>Contact Us</h3>
              <p>
                We would love to hear from you! Whether you have questions,
                feedback, or just want to connect, feel free to reach out to us
                through any of the following
              </p>
            </div>
            <div class="contactReferences">
              <div class="Visit">
                <h5>Visit Us</h5>
                <div class="visitIcon">
                  <a href="https://www.google.com/maps/place/Sri+Uttaradi+Matha,+Bengaluru/@12.9486841,77.5698781,17z/data=!4m6!3m5!1s0x3bae15201bce94ad:0xa7204a3602808638!8m2!3d12.9487232!4d77.5719849!16s%2Fg%2F11g20j5m7t?entry=ttu"
                    target="_blank"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960"
                      width="24px" fill="#000eb4">
                      <path
                        d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z" />
                    </svg>
                    <p>
                      Uttaradi Math, <br />Jayateertha Vidyapeetha,<br />
                      Uttaradi Math road,<br />
                      Basavanagudi
                    </p>
                  </a>
                </div>
              </div>
              <div class="Links">
                <h5>Other Links</h5>
                <a href="">Radio Satyatmavani </a>
                <a href="">Uttaradimath.org </a>
                <a href="">Vadhuvara Samayojane</a>
              </div>
              <div class="Social">
                <h5>Social Media</h5>
                <a style={{ height: "20px", color: "#000eb4" }} href="https://www.instagram.com/yuvatma/" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path fill="#001eff"
                      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                  </svg>
                  <p style={{ display: "contents" }}>Instagram</p>
                </a>
              </div>
            </div>
            <p>&copy 2024 Shri UttaradiMath</p>
          </div>
        </section>
      </main>
    </div>
  );
}

export default App;

function Announcements() {
    return (
        <section class="Announcements inter-paragraph">
            <div class="ShankaChakra">
                <h2>Announcements</h2>
                <p>
                    Join us at Yuvatma for a diverse array of social, cultural, spiritual,
                    and recreational events, crafted to empower and inspire our youth in
                    their personal and collective growth
                </p>
                <div class="Announcementcards">
                    <div class="card">
                        <div class="AnnouncementImage">
                            <img src="/assets/image46.png" alt="Satyadhyana Teertharu" />
                        </div>
                        <div class="AnnoucementContent">
                            <h3>
                                Aug 31, 2024<br />Drama on
                                <a href="https://www.uttaradimath.org/parampara/sri-satyadhyana-tirtha"
                                    style={{ textDecoration: "underline" }}>
                                    Satyadhyana Teertharu</a>
                            </h3>
                            <p>
                                Experience the captivating drama portraying the life of
                                Satyadhyana Teertharu, unfolding in the vibrant city of Mumbai
                                during Chaturmasya
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Announcements;